import React from "react";

// Layout
import SecondaryLanding from "../../../components/layouts/secondary-landing";

// Components
import BreadcrumbAuto from "../../../components/breadcrumb/breadcrumb-auto";
import SEO from "../../../components/seo/seo";
import ArticleCategoriesSection from "../../../components/blog-categories/article-categories-section";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";

import { homeOwnershipCards } from "../../../data/blog-cards-data";

const category = {
  id: "Article-categories_1",
  Name: "Home Ownership",
  Slug: "home-ownership",
  articles: homeOwnershipCards,
  SEO: {
    MetaDescription:
      "Learn about the latest home ownership tips from WaFd Bank including tips for first time home buyers, if you should buy that fixer upper, and more.",
    MetaTitle: "Home Ownership Tips"
  }
};

const HomeOwnershipIndex = ({ location }) => {
  const pageLocation = { location };
  const categoryName = category.Name;

  const metaTitle = category.SEO && category.SEO.MetaTitle;
  const metaDescription = category.SEO && category.SEO.MetaDescription;

  const SEOData = {
    title: metaTitle,
    meta: [
      {
        name: "title",
        property: "og:title",
        content: metaTitle
      },
      {
        name: "description",
        property: "og:description",
        content: metaDescription
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/blog/" + category.Slug
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      }
    ]
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <BreadcrumbAuto {...pageLocation} />
      <SEO {...SEOData} />

      <ArticleCategoriesSection categoryName={categoryName} articles={category.articles} />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default HomeOwnershipIndex;
